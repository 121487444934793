body.elbrus-headless {
  padding-top: 0;
  font-family: unset;
  /*Nest all the Elbrus styles here which break Everest*/
  .container-wrapper {
    @media (max-width: 768px) {
      margin-bottom: -3rem;
    }

    .ni-button {
      margin-top: inherit;
    }
  }

  header.ni-header {
    display: none;
  }

  .site-head {
    top: 70px;
    box-shadow: 0px 10px 12px 0 rgb(0 0 0 / 9%);
  }

  // Hiding elbrus footer
  .ni-footer {
    display: none;
  }

  // Community overrides
  .community-box {
    background-color: #f2ead7;
  }

  .community-container {
    margin-top: 0;
  }

  ol,
  ul,
  li {
    margin-bottom: unset;
    margin-left: unset;
  }

  nav blockquote :last-child,
  nav li :last-child,
  nav p :last-child {
    margin-bottom: 0.4rem;
  }
  .event-partner-list-container
    .event-partner-filters-buttons
    .event-partner-filters-buttons-left {
    button.btn {
      text-transform: unset;

      span {
        top: unset;
      }
    }
  }
}

body.elbrus-headless.inspo,
body.elbrus-headless.search,
body.elbrus-headless.eco-alliance {
  font-size: inherit;
  .fit {
    background-color: #f0f0f0 !important;
  }
}

// Noissue recommends specific changes
body.noissue-recommends.elbrus-headless {
  font-size: inherit;

  footer {
    input {
      min-height: inherit;
      height: 5rem;
      font-size: inherit;
      margin: 2rem 3rem !important;
      border: inherit;
      font-size: 1.875rem;
      max-width: 100% !important;
      width: max-content !important;

      @media (max-width: 1024px) {
        width: 100% !important;
      }
    }
  }

  .absolute.inset-0.flex.h-full.table.w-screen {
    p {
      margin-bottom: 0;
    }
  }

  h3 {
    line-height: 1.5em;
  }

  .bg-indigo.pt-28.w-screen {
    input {
      padding: inherit;
      width: max-content;
      max-width: inherit;
      margin: inherit;
    }
  }

  nav {
    label.block.text-secondary {
      font-size: inherit;
      color: rgba(235, 47, 74, 1);
      margin: unset;
      text-align: start;
      line-height: normal;
    }

    form {
      padding-bottom: unset;

      input {
        padding: 0 2rem;
        margin: 1rem auto;
        line-height: 1.5rem;
        font-size: 1rem;
        border: 2px solid rgba(229, 231, 235, 1);
        height: 4rem;
        border-radius: 0.25rem;
        min-height: 4rem;
      }
    }
  }
}

// Pull this style up style from elbrus to ensure it is loaded before visibility.
article.content-post #post-body {
  @screen md {
    font-family: var(--soleil);
    max-width: 83rem;
    margin: 0 auto;
    text-align: left;
    p {
      margin-bottom: 1.5rem;
    }
  }
}

article h1.content-title {
  @screen md {
    font-family: var(--soleil);
  }
}
